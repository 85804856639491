<template>
  <el-form label-width="100px">
    <el-row>
      <el-col :span="6">
        <el-form-item label="年">
          <el-input v-model="year" readonly></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item label="月">
          <el-input v-model="month" readonly></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item label="区域">
          <el-input v-model="parentOrgName" readonly></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item label="办事处">
          <el-input v-model="orgName" readonly></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item label="登录账号">
          <el-input v-model="userName" readonly></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item label="人员姓名">
          <el-input v-model="realName" readonly></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item label="职位">
          <el-input v-model="posName" readonly></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item label="在勤">
          <el-input v-model="normal" readonly></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item label="加班">
          <el-input v-model="overtimeDays" readonly></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item label="请假">
          <el-input v-model="leaveDays" readonly></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item label="迟到">
          <el-input v-model="lateDays" readonly></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item label="早退">
          <el-input v-model="earlyDays" readonly></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item label="旷工">
          <el-input v-model="absenteeismDays" readonly></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item label="月度工作时长">
          <el-input v-model="allTimes" readonly></el-input>
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      default() {
        return {
          year: '', // 年
          month: '', // 月
          parentOrgName: '', // 区域
          orgName: '', // 办事处
          userName: '', // 登录账号
          realName: '', // 人员姓名
          posName: '', // 职位
          weekDays: '', // 休息
          normal: '', // 在勤
          overtimeDays: '', // 加班
          leaveDays: '', // 请假
          lateDays: '', // 迟到
          earlyDays: '', // 早退
          absenteeismDays: '', // 旷工
          allTimes: '', // 月度工作时长
        };
      },
    },
  },
  watch: {
    value: {
      handler(val) {
        if (val) {
          this.year = val.year;
          this.month = val.month;
          this.parentOrgName = val.parentOrgName;
          this.orgName = val.orgName;
          this.userName = val.userName;
          this.realName = val.realName;
          this.posName = val.posName;
          this.weekDays = val.weekDays;
          this.normal = val.normal;
          this.overtimeDays = val.overtimeDays;
          this.leaveDays = val.leaveDays;
          this.lateDays = val.lateDays;
          this.earlyDays = val.earlyDays;
          this.absenteeismDays = val.absenteeismDays;
          this.allTimes = val.allTimes;
        }
      },
      immediate: true,
      deep: true,
    },
  },
  data() {
    return {
      year: '', // 年
      month: '', // 月
      parentOrgName: '', // 区域
      orgName: '', // 办事处
      userName: '', // 登录账号
      realName: '', // 人员姓名
      posName: '', // 职位
      weekDays: '', // 休息
      normal: '', // 在勤
      overtimeDays: '', // 加班
      leaveDays: '', // 请假
      lateDays: '', // 迟到
      earlyDays: '', // 早退
      absenteeismDays: '', // 旷工
      allTimes: '', // 月度工作时长
    };
  },
};
</script>
