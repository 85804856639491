<template>
  <div class="card-list">
    <div class="card-item" v-for="(item, index) in monthList" :key="index">
      <div class="card-item-content">
        <div class="card-item-content-title">{{ item.title }}</div>
        <div class="card-item-content-value">{{ item.status }}{{item.status ? '/' : ''}}{{item.times}}小时</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
    },
  },
  watch: {
    value: {
      handler(val) {
        if (val) {
          const result = [];
          for (const key in val) {
            if (/^day[0-9]/.test(key)) {
              const index = key.replace(/day/, '') || 0;
              result.push({
                title: key,
                status: val[key], // 当天考勤状态
                times: val[`dayTime${index}`], // 当天工作时长
              });
            }
          }
          this.monthList = result;
        }
      },
      immediate: true,
      deep: true,
    },
  },
  data() {
    return {
      monthList: [], // title,value
    };
  },
};
</script>

<style lang="less" scoped>
.card-list {
  margin-left: 56px;
  padding: 0 20px;
  box-sizing: border-box;
}
.card-item {
  float: left;
  width: 14%;
  height: 60px;
  padding: 0 4px;
  box-sizing: border-box;
  margin-bottom: 4px;
  text-align: center;
  &-content {
    height: 100%;
    border: 1px solid #dcdfe6;
    border-radius: 4px;
    &-title {
      margin-top: -2px;
    }
    &-value {
      margin-top: -2px;
    }
  }
}
</style>
