<script>
import Form, { formCreate } from '../../../../../components/form';
import BasicInfo from './components/basic_info.vue';
import BasicList from './components/basic_list.vue';

formCreate.component('sfaAttendanceMonthAttendanceBasicInfo', BasicInfo);
formCreate.component('sfaAttendanceMonthAttendanceBasicList', BasicList);

export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      buttons: {
        submit: false,
      },
    };
  },
  methods: {
    /** @desc 设置详情的value值 */
    setDetailValue() {
      this.setValue({
        basicInfo: { ...this.formConfig.row },
        basicList: { ...this.formConfig.row },
      });
    },
  },
  async created() {
    await this.getFormRule('sfa_attendance_monthly_attendance_check');
    this.setDetailValue();
  },
};
</script>
