var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    { attrs: { "label-width": "100px" } },
    [
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { span: 6 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "年" } },
                [
                  _c("el-input", {
                    attrs: { readonly: "" },
                    model: {
                      value: _vm.year,
                      callback: function ($$v) {
                        _vm.year = $$v
                      },
                      expression: "year",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 6 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "月" } },
                [
                  _c("el-input", {
                    attrs: { readonly: "" },
                    model: {
                      value: _vm.month,
                      callback: function ($$v) {
                        _vm.month = $$v
                      },
                      expression: "month",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 6 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "区域" } },
                [
                  _c("el-input", {
                    attrs: { readonly: "" },
                    model: {
                      value: _vm.parentOrgName,
                      callback: function ($$v) {
                        _vm.parentOrgName = $$v
                      },
                      expression: "parentOrgName",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 6 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "办事处" } },
                [
                  _c("el-input", {
                    attrs: { readonly: "" },
                    model: {
                      value: _vm.orgName,
                      callback: function ($$v) {
                        _vm.orgName = $$v
                      },
                      expression: "orgName",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 6 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "登录账号" } },
                [
                  _c("el-input", {
                    attrs: { readonly: "" },
                    model: {
                      value: _vm.userName,
                      callback: function ($$v) {
                        _vm.userName = $$v
                      },
                      expression: "userName",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 6 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "人员姓名" } },
                [
                  _c("el-input", {
                    attrs: { readonly: "" },
                    model: {
                      value: _vm.realName,
                      callback: function ($$v) {
                        _vm.realName = $$v
                      },
                      expression: "realName",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 6 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "职位" } },
                [
                  _c("el-input", {
                    attrs: { readonly: "" },
                    model: {
                      value: _vm.posName,
                      callback: function ($$v) {
                        _vm.posName = $$v
                      },
                      expression: "posName",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 6 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "在勤" } },
                [
                  _c("el-input", {
                    attrs: { readonly: "" },
                    model: {
                      value: _vm.normal,
                      callback: function ($$v) {
                        _vm.normal = $$v
                      },
                      expression: "normal",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 6 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "加班" } },
                [
                  _c("el-input", {
                    attrs: { readonly: "" },
                    model: {
                      value: _vm.overtimeDays,
                      callback: function ($$v) {
                        _vm.overtimeDays = $$v
                      },
                      expression: "overtimeDays",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 6 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "请假" } },
                [
                  _c("el-input", {
                    attrs: { readonly: "" },
                    model: {
                      value: _vm.leaveDays,
                      callback: function ($$v) {
                        _vm.leaveDays = $$v
                      },
                      expression: "leaveDays",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 6 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "迟到" } },
                [
                  _c("el-input", {
                    attrs: { readonly: "" },
                    model: {
                      value: _vm.lateDays,
                      callback: function ($$v) {
                        _vm.lateDays = $$v
                      },
                      expression: "lateDays",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 6 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "早退" } },
                [
                  _c("el-input", {
                    attrs: { readonly: "" },
                    model: {
                      value: _vm.earlyDays,
                      callback: function ($$v) {
                        _vm.earlyDays = $$v
                      },
                      expression: "earlyDays",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 6 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "旷工" } },
                [
                  _c("el-input", {
                    attrs: { readonly: "" },
                    model: {
                      value: _vm.absenteeismDays,
                      callback: function ($$v) {
                        _vm.absenteeismDays = $$v
                      },
                      expression: "absenteeismDays",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 6 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "月度工作时长" } },
                [
                  _c("el-input", {
                    attrs: { readonly: "" },
                    model: {
                      value: _vm.allTimes,
                      callback: function ($$v) {
                        _vm.allTimes = $$v
                      },
                      expression: "allTimes",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }