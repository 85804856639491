<script>
// eslint-disable-next-line no-unused-vars
import _ from 'xe-utils';
import { log } from 'mathjs';
import TablePage from '../../../../../components/table_page';
// eslint-disable-next-line no-unused-vars
import request from '../../../../../utils/request';
import * as utils from '../../../utils/index';
import FormDetail from './form_detail.vue';

const currentDate = utils.getCurrentDate();
const currentString = `${currentDate.year}-${currentDate.month}`;

export default {
  name: 'table-component',
  extends: TablePage,
  components: { FormDetail },
  data() {
    return {
      requestUrl: '/sfa/attendanceReportController/monthlyAttendanceReport',
      allColumn: [],
      /** @todo 这个是回显的控件 */
      searchFormData: {
        yearMonth: currentString,
      },
      /** @desc 这个是请求的参数 */
      formData: {
        yearMonth: currentString,
      },
      /** @todo 自定义列表配置搜索条件，增加字段和控件 */
      configs: {
        formConfig: {
          searchList: [
            {
              isSearch: true,
              search: true,
              field: 'yearMonth',
              title: '名称',
              span: 6,
              render: {
                name: 'ElDatePicker',
                props: {
                  placeholder: '请选择年月',
                  type: 'month',
                  value: new Date(currentDate.year, currentDate.month),
                  defaultValue: new Date(currentDate.year, currentDate.month),
                  valueFormat: 'yyyy-MM',
                  clearable: true,
                },
              },
            },
          ],
        },
      },
      formConfig: {},
      modalConfig: {
        title: '新增',
        visible: false,
        type: '',
      },
    };
  },
  methods: {
    modalClick({ val, row }) {
      if (val.code === 'view') {
        this.btnCheck(row);
      }
    },
    /** @desc 折叠搜索条件 */
    foldSearchList(n) {
      n.map((v, k) => {
        const rowData = v;
        if (k > 3) {
          rowData.folding = true;
        }
        return rowData;
      });
      return n;
    },
    /** @override 重写重置按钮 */
    beforeSearchEvent({ $event }) {
      if ($event.type === 'reset') {
        this.formData.yearMonth = currentString;
        this.searchFormData.yearMonth = currentString;
      }
      return true;
    },
    btnCheck(row = {}) {
      this.formName = 'FormDetail';
      this.formConfig.row = { ...row };
      this.modalConfig.title = '查看详情';
      this.openFull();
    },

    /** @override 获取数据之后 */
    async afterGetList() {
      /** @todo
       * 1.动态添加日期字段
       * 2.根据每行新返回的nowDay字段，代表，当天的 日期数，是几号
       * 3.只取当前以前的天数，进行增加表格列进行展示
       * 4.因该方法是每次请求和再原有数组columns上增加字段,所有需要去重
      */
      const res = await request.post('/mdm/mdmtableconfig/query', {
        functionCode: 'sfa_attendance_monthly_attendance',
        function_code: 'sfa_attendance_monthly_attendance',
        parentCode: 'CRM20201128000000133',
      });
      const tempColumns = res.result.column;
      let tempDay = 0;
      let currentMonth = null;
      if (this.searchFormData.date) {
        // tempDay = _.getDayOfMonth(new Date(this.searchFormData.date));
        const yearAndMonth = this.searchFormData.date.split('-');
        const num = yearAndMonth[1];
        console.log(num, 999);
        if (num === '01') {
          currentMonth = `${yearAndMonth[0] - 1}-12`;
        } else {
          currentMonth = `${yearAndMonth[0]}-${yearAndMonth[1] - 1}`;
        }
        console.log(currentMonth);
      } else {
        // tempDay = _.getDayOfMonth(new Date());
        const date = new Date();
        const year = date.getFullYear();
        const month = date.getMonth();
        if (month === 0) {
          currentMonth = `${year - 1}-12`;
        } else {
          currentMonth = `${year}-${month}`;
        }
        console.log(currentMonth);
      }
      const currentMonthStr = currentMonth.split('-');
      console.log(currentMonthStr);
      currentMonth = new Date(currentMonth);
      console.log(currentMonth);
      tempDay = _.getDayOfMonth(currentMonth);
      const monthNew = Number(currentMonthStr[1]) === 12 ? 1 : Number(currentMonthStr[1]) + 1;
      const days = [];// 展示得天数数组
      if (monthNew === 12) {
        days.push({
          field: 'specMonth11Day26',
          title: '11-26',
        });
        days.push({
          field: 'specMonth11Day27',
          title: '11-27',
        });
        days.push({
          field: 'specMonth11Day28',
          title: '11-28',
        });
        days.push({
          field: 'specMonth11Day29',
          title: '11-29',
        });
        days.push({
          field: 'specMonth11Day30',
          title: '11-30',
        });
        // for (let i = 26; i <= tempDay; i += 1) {
        //   days.push({
        //     field: `day${i}`,
        //     title: `${monthNew - 1}-${i.toString()}`,
        //   });
        // }
        for (let j = 1; j <= 31; j += 1) {
          days.push({
            field: `day${j}`,
            title: `${monthNew}-${j.toString()}`,
          });
        }
      } else if (monthNew === 1) {
        for (let i = 1; i <= 25; i += 1) {
          days.push({
            field: `day${i}`,
            title: `${monthNew}-${i.toString()}`,
          });
        }
      } else {
        for (let i = 26; i <= tempDay; i += 1) {
          days.push({
            field: `day${i}`,
            title: `${monthNew - 1}-${i.toString()}`,
          });
        }
        for (let j = 1; j <= 25; j += 1) {
          days.push({
            field: `day${j}`,
            title: `${monthNew}-${j.toString()}`,
          });
        }
      }
      console.log(tempDay, days);
      this.configs.columns = utils.duplicate([...tempColumns, ...days], 'field');
    },
  },
  created() {
    this.getConfigList('sfa_attendance_monthly_attendance').then(() => {
      this.foldSearchList(this.searchList);
    });
  },
};
</script>
