var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "card-list" },
    _vm._l(_vm.monthList, function (item, index) {
      return _c("div", { key: index, staticClass: "card-item" }, [
        _c("div", { staticClass: "card-item-content" }, [
          _c("div", { staticClass: "card-item-content-title" }, [
            _vm._v(_vm._s(item.title)),
          ]),
          _c("div", { staticClass: "card-item-content-value" }, [
            _vm._v(
              _vm._s(item.status) +
                _vm._s(item.status ? "/" : "") +
                _vm._s(item.times) +
                "小时"
            ),
          ]),
        ]),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }